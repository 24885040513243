import PropTypes from 'prop-types';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Link = ({ children, className, label, href, offset }) => {
  if (href && href.startsWith('#')) {
    // Scroll to anchor link if href starts with #
    return (
      <AnchorLink className={className} href={href} offset={offset}>
        {children || label}
      </AnchorLink>
    );
  }

  return (
    <a className={className} href={href}>
      {children || label}
    </a>
  );
};

Link.propTypes = {
  label: PropTypes.string,
  href: PropTypes.string,
  offset: PropTypes.string,
};

Link.defaultProps = {
  label: '',
  href: '',
  offset: '0',
};

export default Link;
