import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import FeatureBlock from 'common/src/components/FeatureBlock';
import { IconWrapper } from 'common/src/components/FeatureBlock/featureBlock.style';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import Text from 'common/src/components/Text';
import Link from '../../../components/link';

import ContentSectionWrapper from './contentSection.style';

const center = 'center';
const left = 'left';
const right = 'right';

const ContentSection = ({
  row,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  imageArea,
  imageWrapper,
  textArea,
  title,
  description,
  featureArea,
  featureTitle,
  featureSubTitle,
  featureDescription,
  btnArea,
  btnStyle,
  data,
}) => {
  const imageLocation = get(data, 'image_location', left);
  const imageAlignment = get(data, 'image_alignment', center);
  const xsImageAlignment = get(data, 'xs_image_alignment', center);
  const imageOnLeft = imageLocation === left;

  let imageAreaStyle = { ...imageArea };
  // Set margin for different sizes to auto by default
  imageAreaStyle.marginLeft = ['auto', 'auto', 'auto'];
  imageAreaStyle.marginRight = ['auto', 'auto', 'auto'];
  // Set margin for default and xs size
  if (xsImageAlignment === left) {
    // Set margin for default and xs
    imageAreaStyle.marginLeft[0] = '0px';
    imageAreaStyle.marginRight[0] = 'auto';
    imageAreaStyle.marginLeft[1] = '0px';
    imageAreaStyle.marginRight[1] = 'auto';
  } else if (xsImageAlignment === right) {
    // Set margin for default and xs
    imageAreaStyle.marginLeft[0] = 'auto';
    imageAreaStyle.marginRight[0] = '0px';
    imageAreaStyle.marginLeft[1] = 'auto';
    imageAreaStyle.marginRight[1] = '0px';
  }
  // Set no margin for sm and above
  imageAreaStyle.marginLeft[2] = '0px';
  imageAreaStyle.marginRight[2] = '0px';

  let imageStyle = { maxHeight: '735px' };
  if (imageAlignment === left) {
    imageStyle.marginRight = 'auto';
  } else if (imageAlignment === right) {
    imageStyle.marginLeft = 'auto';
  } else {
    imageStyle.margin = 'auto';
  }

  const TextAreaButton = () =>
    get(data, 'body.button.label') ? (
      <Box {...btnArea}>
        <Button
          as={Link}
          href={get(data, 'body.button.url')}
          offset={get(data, 'body.button.offset', 0)}
          title={get(data, 'body.button.label')}
          {...btnStyle}
        />
      </Box>
    ) : null;

  const prismicImgUrl = get(data, 'image.url');

  return (
    <ContentSectionWrapper id={data.section_key}>
      <Box {...sectionHeader}>
        <Text content={data.heading} {...sectionTitle} />
        <Heading content={data.sub_heading} {...sectionSubTitle} />
      </Box>
      <Box
        className={`image-${imageLocation}`}
        {...row}
        flexDirection={imageOnLeft ? 'row' : 'row-reverse'}
      >
        {prismicImgUrl ? (
          <Box className="image-area" {...imageAreaStyle}>
            <Card {...imageWrapper}>
              <Fade delay={60}>
                <Image
                  srcSet={`${prismicImgUrl}&w=240 240w, ${prismicImgUrl}&w=480 480w, ${prismicImgUrl}&w=720 720w`}
                  src={`${prismicImgUrl}&w=480`}
                  alt={get(data, 'image.alt')}
                  sizes="(max-width: 768px) 50vw, (max-width: 1220px) 40vw, 38vw"
                  loading="lazy"
                  {...imageStyle}
                />
              </Fade>
            </Card>
          </Box>
        ) : null}

        <Box className="text-area" {...textArea}>
          <Box>
            <FeatureBlock
              title={<Heading content={data.body.heading} {...title} />}
              description={<Text content={data.body.copy} {...description} />}
            />
          </Box>

          <Box className={get(data, 'body.list.type')}>
            {get(data, 'body.list.items', []).map((feature, index) => (
              <Box
                key={index}
                className="feature-content__item"
                {...featureArea}
              >
                {feature.icon ? (
                  <IconWrapper className="icon__wrapper">
                    <i className={feature.icon}></i>
                  </IconWrapper>
                ) : null}
                <div>
                  {feature.title ? (
                    <Heading
                      content={feature.title}
                      {...featureTitle}
                      className={`${data.body.list.type}-feature-title`}
                    />
                  ) : null}
                  {feature.subtitle ? (
                    <Heading
                      content={feature.subtitle}
                      {...featureSubTitle}
                      className={`${data.body.list.type}-feature-subtitle`}
                    />
                  ) : null}
                  {feature.description ? (
                    <Text
                      content={feature.description}
                      {...featureDescription}
                      className={`${data.body.list.type}-feature-description`}
                    />
                  ) : null}
                </div>
              </Box>
            ))}
          </Box>

          <TextAreaButton />
        </Box>
      </Box>
    </ContentSectionWrapper>
  );
};

ContentSection.propTypes = {
  row: PropTypes.object,
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  imageArea: PropTypes.object,
  imageWrapper: PropTypes.object,
  textArea: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  featureTitle: PropTypes.object,
  featureSubTitle: PropTypes.object,
  featureDescription: PropTypes.object,
  data: PropTypes.object,
};

ContentSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  sectionHeader: {
    marginTop: ['40px', '80px'],
    marginBottom: ['40px', '80px'],
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  sectionTitle: {
    as: 'span',
    marginBottom: '10px',
    marginTop: '0px',
    fontSize: '14px',
    color: '#A73494',
    display: 'block',
    fontWeight: '700',
    textAlign: 'center',
    letterSpacing: '0.15em',
  },
  sectionSubTitle: {
    as: 'h2',
    textAlign: 'center',
    fontSize: ['20px', '24px', '26px', '28px', '30px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    marginBottom: '0px',
    marginTop: '0px',
  },
  imageArea: {
    height: 'auto',
    position: 'relative',
    width: ['50%', '50%', '40%', '40%', '38%'],
    alignSelf: 'center',
  },
  imageWrapper: {
    boxShadow: 'none',
    width: '100%',
  },
  textArea: {
    width: ['90%', '90%', '450px', '580px', '730px'],
    paddingLeft: ['0px', '0px', '15px'],
    paddingRight: ['0px', '0px', '15px'],
    marginLeft: ['auto', 'auto', '0px'],
    marginRight: ['auto', 'auto', '0px'],
    alignSelf: 'center',
  },
  title: {
    fontSize: ['26px', '26px', '30px', '40px'],
    lineHeight: '1.5',
    fontWeight: '300',
    letterSpacing: '-0.025em',
    color: 'rgb(15, 33, 55)',
    marginTop: ['33px', '33px', '33px', '0px'],
    marginBottom: '20px',
  },
  description: {
    marginBottom: '33px',
    marginTop: '0px',
    fontSize: '16px',
    color: 'rgba(52, 61, 72, 0.8)',
    lineHeight: '2.1',
  },
  featureArea: {
    marginBottom: '1rem',
  },
  featureTitle: {
    as: 'h3',
    fontSize: '20px',
    marginBottom: '8px',
    marginTop: '0px',
    color: 'rgb(15, 33, 55)',
    fontWeight: '400',
    lineHeight: '1.5',
    letterSpacing: '-0.02em',
  },
  featureSubTitle: {
    as: 'h4',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '8px',
    marginTop: '0px',
    color: 'rgb(15, 33, 55, 0.6)',
    lineHeight: '1',
  },
  featureDescription: {
    marginBottom: '0px',
    marginTop: '0px',
    fontSize: '15px',
    color: 'rgba(52, 61, 72, 0.8)',
    lineHeight: '1.84',
  },
  btnArea: {
    marginTop: '33px',
    marginBottom: ['30px', '30px', '0px'],
  },
  btnStyle: {
    minWidth: ['120px', '156px'],
    fontSize: '16px',
    fontWeight: '700',
  },
};

export default ContentSection;
