import get from 'lodash/get';
import { graphql } from 'gatsby';
import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import Helmet from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import { agencyTheme } from 'common/src/theme/agency';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, AgencyWrapper } from '../containers/Agency/agency.style';
import Navbar from '../containers/Agency/Navbar';
import BannerSection from '../containers/Agency/BannerSection';
import ContentSection from '../containers/Agency/ContentSection';
import ContactUs from '../containers/Agency/ContactUs';
import Footer from '../containers/Agency/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';
import { asTextOrNull } from '../utils/richTextUtils';

export const query = graphql`
  {
    site {
      siteMetadata {
        navbarHeight
      }
    }
    prismic {
      allHome_pages {
        edges {
          node {
            hero_image
            hero_heading
            hero_description
            cta_links {
              label
              url
            }
            body {
              ... on PRISMIC_Home_pageBody1_image_with_features {
                type
                primary {
                  anchor_id
                  section_heading
                  section_description
                  image_location
                  image_alignment
                  xs_image_alignment
                  image
                  heading
                  description
                  cta_label
                  cta_url
                  display_feature_as
                }
                fields {
                  icon
                  heading
                  subheading
                  description
                }
              }
              ... on PRISMIC_Home_pageBodyContact {
                type
                primary {
                  anchor_id
                  section_heading
                  section_description
                  image
                  cta_label
                  cta_location
                }
                fields {
                  location
                  heading
                  description
                }
              }
            }
            lang
            seo_title
            seo_description
            seo_keywords
            social_cards {
              ... on PRISMIC_Home_pageSocial_cardsGeneral_card {
                type
                primary {
                  image
                  title
                  description
                }
              }
              ... on PRISMIC_Home_pageSocial_cardsTwitter_card {
                type
                primary {
                  card_type
                  image
                  title
                  description
                  site
                }
              }
            }
          }
        }
      }
      allLayouts {
        edges {
          node {
            header_logo
            header_body {
              ... on PRISMIC_LayoutHeader_bodyFlat_menu {
                primary {
                  label
                  url
                }
              }
            }
            footer_logo
            footer_text
            footer_body {
              ... on PRISMIC_LayoutFooter_bodyGroup_menus {
                primary {
                  label
                }
                fields {
                  label
                  url
                  media_link {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__FileLink {
                      url
                    }
                    ... on PRISMIC__ImageLink {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ({ data }) => {
  const homepage = data.prismic.allHome_pages.edges.slice(0, 1).pop();

  if (!homepage) return null;

  const {
    hero_image,
    hero_heading,
    hero_description,
    cta_links,
    body,
    lang,
    seo_title,
    seo_description,
    seo_keywords,
    social_cards,
  } = homepage.node;

  const seo = {
    lang,
    title: seo_title,
    description: seo_description,
    keywords: seo_keywords,
    social_cards,
  };

  const offset = get(data, 'site.siteMetadata.navbarHeight', 0);

  const banner = {
    imageUrl: hero_image.url,
    heading: asTextOrNull(hero_heading),
    description: asTextOrNull(hero_description),
    buttons: cta_links.map((link) => ({
      ...link,
      offset: offset,
    })),
  };

  const transformContent = (sectionData = {}) => ({
    section_key: get(sectionData, 'primary.anchor_id'),
    heading: asTextOrNull(get(sectionData, 'primary.section_heading')),
    sub_heading: asTextOrNull(get(sectionData, 'primary.section_description')),
    image: get(sectionData, 'primary.image'),
    image_location: get(
      sectionData,
      'primary.image_location',
      'left'
    ).toLowerCase(),
    image_alignment: get(
      sectionData,
      'primary.image_alignment',
      'center'
    ).toLowerCase(),
    xs_image_alignment: get(
      sectionData,
      'primary.xs_image_alignment',
      'center'
    ).toLowerCase(),
    body: {
      heading: asTextOrNull(get(sectionData, 'primary.heading')),
      copy: asTextOrNull(get(sectionData, 'primary.description')),
      list: {
        type: get(
          sectionData,
          'primary.display_feature_as',
          'grid'
        ).toLowerCase(),
        items: get(sectionData, 'fields', []).map((listItem) => ({
          icon: listItem.icon,
          title: asTextOrNull(listItem.heading),
          subtitle: asTextOrNull(listItem.subheading),
          description: asTextOrNull(listItem.description),
        })),
      },
      button: {
        label: get(sectionData, 'primary.cta_label'),
        url: get(sectionData, 'primary.cta_url'),
        offset: offset,
      },
    },
  });

  const transformContact = (sectionData = {}) => ({
    section_key: get(sectionData, 'primary.anchor_id'),
    heading: asTextOrNull(get(sectionData, 'primary.section_heading')),
    sub_heading: asTextOrNull(get(sectionData, 'primary.section_description')),
    image: get(sectionData, 'primary.image'),
    body: {
      list: {
        items: get(sectionData, 'fields', []).map((listItem) => ({
          location: listItem.location,
          title: asTextOrNull(listItem.heading),
          description: listItem.description,
        })),
      },
      button: {
        label: get(sectionData, 'primary.cta_label'),
        location: get(sectionData, 'primary.cta_location'),
      },
    },
  });

  const layout = data.prismic.allLayouts.edges.slice(0, 1).pop();

  const {
    header_logo,
    header_body,
    footer_logo,
    footer_text,
    footer_body,
  } = layout.node;

  const navbar = {
    logo: header_logo,
    menuItems: (header_body || []).map((menuItem) => ({
      label: get(menuItem, 'primary.label', ''),
      path: get(menuItem, 'primary.url', ''),
      offset: offset,
    })),
  };

  const footer = {
    logo: footer_logo,
    text: asTextOrNull(footer_text),
    menuGroups: (footer_body || []).map((menuGroup) => ({
      id: get(menuGroup, 'primary.label', '').replace(' ', '_'),
      label: get(menuGroup, 'primary.label', ''),
      menuItems: get(menuGroup, 'fields', []).map((menuItem) => ({
        ...menuItem,
        offset: offset,
      })),
    })),
  };

  return (
    <ThemeProvider theme={agencyTheme}>
      <Fragment>
        <SEO data={seo} />
        <ResetCSS />
        <Helmet>
          <script
            defer
            src="./HelpScout.js"
            api_key={process.env.GATSBY_HELPSCOUT_BEACON_ID}
          ></script>
        </Helmet>
        <GlobalStyle />
        {/* End of agency head section */}
        {/* Start agency wrapper section */}
        <AgencyWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar data={navbar} />
            </DrawerProvider>
          </Sticky>
          <BannerSection data={banner} />
          {body.map((data, index) => {
            switch (data.type) {
              case '1_image_with_features':
                return (
                  <ContentSection data={transformContent(data)} key={index} />
                );
              case 'contact':
                return <ContactUs data={transformContact(data)} key={index} />;
              default:
                return null;
            }
          })}
          <Footer data={footer} />
        </AgencyWrapper>
        {/* End of agency wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};
