import styled from 'styled-components';
import { themeGet } from 'styled-system';

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .site-nav__list {
    margin: 0;
    padding: 0;
    display: none;

    @media screen and (min-width: 1120px) {
      display: flex;
    }

    a {
      color: black;
      margin-left: 20px;
      margin-right: 20px;
      transition: all 200ms ease;
      transition: color 0s ease;

      &:hover {
        color: ${themeGet('colors.primary', '#028489')};
        transition: all 200ms ease;
      }
    }

    .is-current a {
      background-color: #a73494;
      color: white;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 50px;
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  // Hide hamburger on desktop
  @media screen and (min-width: 1120px) {
    .drawer-wrapper {
      display: none;
    }
  }

  @media (min-width: 320px) {
    padding-left: 25px;
    padding-right: 23px;
  }
  @media (min-width: 768px) {
    max-width: 750px;
  }
  @media (min-width: 992px) {
    max-width: 970px;
  }
  @media (min-width: 1200px) {
    max-width: 1170px;
  }
`;

export { Container };
