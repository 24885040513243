import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Button from 'common/src/components/Button';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Heading from 'common/src/components/Heading';
import Text from 'common/src/components/Text';
import Container from 'common/src/components/UI/Container';
import BannerWrapper from './bannerSection.style';
import Particles from '../../Agency/Particle';
import Link from '../../../components/link';

const BannerSection = ({
  row,
  col,
  title,
  description,
  btnStyle,
  outlineBtnStyle,
  data,
}) => {
  const bannerImageStyle = {
    backgroundImage: 'url(' + data.imageUrl + ')',
  };

  const BannerTitle = () =>
    data.heading ? <Heading content={data.heading} {...title} /> : null;

  const BannerDescription = () =>
    data.description ? (
      <Text content={data.description} {...description} />
    ) : null;

  const ButtonGroup = () => (
    <Fragment>
      {data.buttons.map((button, index) =>
        index + 1 === data.buttons.length ? (
          // Last item
          <Button
            key={index}
            as={Link}
            href={button.url}
            offset={button.offset}
            title={button.label}
            variant="textButton"
            icon={<i className="flaticon-next" />}
            {...outlineBtnStyle}
          />
        ) : (
          <Button
            key={index}
            as={Link}
            href={button.url}
            offset={button.offset}
            title={button.label}
            {...btnStyle}
          />
        )
      )}
    </Fragment>
  );

  return (
    <BannerWrapper id="home" style={bannerImageStyle}>
      <Particles />
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <FeatureBlock
              title={<BannerTitle />}
              description={<BannerDescription />}
              button={<ButtonGroup />}
            />
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
  data: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '70%', '60%', '50%'],
  },
  title: {
    fontSize: ['26px', '34px', '42px', '55px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.31',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '2.1',
    mb: '0',
  },
  btnStyle: {
    minWidth: ['120px', '156px'],
    fontSize: '16px',
    fontWeight: '700',
  },
  outlineBtnStyle: {
    minWidth: ['130px', '156px'],
    fontSize: '16px',
    fontWeight: '700',
    color: '#0f2137',
    p: '5px 10px',
  },
};

export default BannerSection;
