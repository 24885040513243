import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Logo from 'common/src/components/UIElements/Logo';
import Container from 'common/src/components/UI/Container';
import FooterWrapper, { List, ListItem } from './footer.style';
import { Link as PrismicLink } from 'prismic-reactjs';
import { linkResolver } from '../../../utils/linkResolver';
import Link from '../../../components/link';

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  logoStyle,
  textStyle,
  data,
}) => {
  const { url: logoUrl = '', alt: logoAlt = '' } = data.logo;

  return (
    <FooterWrapper id="footerSection">
      <Container>
        <Box className="row" {...row}>
          <Box {...colOne}>
            <Logo
              href="#"
              logoSrcset={`${logoUrl}&w=128 1x, ${logoUrl}&w=256 2x, ${logoUrl}&w=384 3x`}
              logoSrc={`${logoUrl}&w=256`}
              title={logoAlt}
              logoStyle={logoStyle}
            />
            <Text content={data.text} {...textStyle} />
          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo}>
            {data.menuGroups.map((menuGroup, groupIndex) => (
              <Box className="col" {...col} key={groupIndex}>
                <Heading content={menuGroup.label} {...titleStyle} />
                <List>
                  {menuGroup.menuItems.map((item, itemIndex) => (
                    <ListItem key={`list__item-${itemIndex}`}>
                      {/* Open media in new tab */}
                      {item.media_link ? (
                        <a
                          href={PrismicLink.url(item.media_link, linkResolver)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.label}
                        </a>
                      ) : (
                        <Link href={item.url} offset={item.offset}>
                          {item.label}
                        </Link>
                      )}
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
          {/* End of footer List column */}
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  data: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-4px',
    mr: '-4px',
  },
  // Footer col one style
  colOne: {
    width: ['100%', '30%', '35%', '23%'],
    mt: [0, '13px'],
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '70%', '65%', '77%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '25%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
  // widget title default style
  titleStyle: {
    color: '#343d48',
    fontSize: '16px',
    fontWeight: '700',
  },
  // Default logo size
  logoStyle: {
    width: '128px',
    height: '47px',
    mb: '15px',
  },
  // widget text default style
  textStyle: {
    color: '#0f2137',
    fontSize: '16px',
    mb: '10px',
  },
};

export default Footer;
