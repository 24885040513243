import styled from 'styled-components';
import { themeGet } from 'styled-system';

const ContentSectionWrapper = styled.section`
  overflow: hidden;

  // Set max width for extra large resolutions
  @media (min-width: 2000px) {
    width: 2000px;
    margin-left: auto;
    margin-right: auto;
  }

  .image-left {
    .text-area {
      margin-right: auto;
    }
  }

  .image-right {
    .text-area {
      margin-left: auto;
    }
  }

  .list-feature-description {
    font-size: 16px;
    color: rgb(52, 61, 72);
    font-weight: 400;
    line-height: 1.5;
  }

  .unstyled-feature-subtitle {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .icon__wrapper {
      width: 54px;
      height: 54px;
      border-radius: 50%;
      background: #93d26e;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: #ffffff;
      overflow: hidden;
      margin-top: 6px;
      margin-right: 22px;
      flex-shrink: 0;
      position: relative;
      background: linear-gradient(
        -60deg,
        rgba(241, 39, 17, 0.8),
        rgba(245, 175, 25, 0.8)
      );
      .flaticon-flask {
        &:before {
          margin-left: 6px;
        }
      }
      &:before,
      &:after {
        content: '';
        width: 24px;
        height: 100%;
        position: absolute;
      }
      &:before {
        transform: rotate(45deg);
        background-color: rgba(255, 255, 255, 0.15);
      }
      &:after {
        transform: rotate(-45deg);
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    .feature-content__item {
      display: flex;

      @media screen and (min-width: 990px) {
        flex-basis: 45%;
      }

      &:nth-child(2) {
        .icon__wrapper {
          background: linear-gradient(
            -60deg,
            rgba(50, 207, 167, 0.9),
            rgba(150, 201, 61, 0.9)
          );
        }
      }
      &:nth-child(3) {
        .icon__wrapper {
          background: linear-gradient(
            -60deg,
            rgba(236, 0, 140, 0.85),
            rgba(255, 103, 103, 0.85)
          );
        }
      }
      &:nth-child(4) {
        .icon__wrapper {
          background: linear-gradient(
            -60deg,
            rgba(47, 128, 237, 0.85),
            rgba(86, 204, 242, 0.85)
          );
        }
      }
      &:nth-child(5) {
        .icon__wrapper {
          background: linear-gradient(
            -60deg,
            rgba(0, 57, 115, 0.85),
            rgba(299, 299, 199, 0.85)
          );
        }
      }
      &:nth-child(6) {
        .icon__wrapper {
          background: linear-gradient(
            -60deg,
            rgba(110, 72, 170, 0.85),
            rgba(192, 91, 210, 0.85)
          );
        }
      }
    }
  }

  .list {
    .feature-content__item {
      align-items: center;
      margin-bottom: 14px;
      display: flex;

      .icon__wrapper {
        color: ${themeGet('colors.primary', '#028489')};
        margin-right: 10px;
        position: relative;
      }
    }
  }
`;

export default ContentSectionWrapper;
