import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import NavbarWrapper from 'common/src/components/Navbar';
import Drawer from 'common/src/components/Drawer';
import Logo from 'common/src/components/UIElements/Logo';
import HamburgMenu from 'common/src/components/HamburgMenu';
import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';
import { Container } from './navbar.style';
import { DrawerContext } from 'common/src/contexts/DrawerContext';

const Navbar = ({ navbarStyle, logoStyle, data }) => {
  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  const { url: logoUrl = '', alt: logoAlt = '' } = data.logo;

  return (
    <NavbarWrapper {...navbarStyle}>
      <Container>
        <Logo
          href="#"
          logoSrcset={`${logoUrl}&w=150 1x, ${logoUrl}&w=300 2x, ${logoUrl}&w=450 3x`}
          logoSrc={`${logoUrl}&w=300`}
          title={logoAlt}
          logoStyle={logoStyle}
        />
        <div
          className="site-nav"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <ScrollSpyMenu
            className="site-nav__list"
            menuItems={data.menuItems}
            offset={-100}
          />

          <div className="drawer-wrapper">
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                menuItems={data.menuItems}
                drawerClose={true}
                offset={-100}
              />
            </Drawer>
          </div>
        </div>
      </Container>
    </NavbarWrapper>
  );
};

// Navbar style props
Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  data: PropTypes.object,
};

Navbar.defaultProps = {
  // Default navbar style
  navbarStyle: {
    minHeight: '70px',
  },
  // Default logo size
  logoStyle: {
    width: '150px',
    height: '55px',
  },
};

export default Navbar;
