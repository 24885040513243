import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LinkStyle from './link.style';
import { base, themed } from '../base';

const LinkWrapper = styled('a')(
  {
    textDecoration: 'none',
  },
  base,
  themed('Link')
);

const Link = ({ children, ...props }) => (
  <LinkWrapper {...props}>{children}</LinkWrapper>
);

const LinkStyled = ({ children, ...props }) => (
  <LinkStyle {...props}>{children}</LinkStyle>
);

export default Link;
export { LinkStyled };

Link.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.any.isRequired,
  ...base.propTypes,
};

LinkStyled.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.any.isRequired,
  ...base.propTypes,
  visibility: PropTypes.bool,
};

Link.defaultProps = {
  as: 'a',
  m: 0,
  display: 'inline-block',
  visibility: 'true',
};

LinkStyled.defaultProps = {
  as: 'a',
  m: 0,
  display: 'inline-block',
};
