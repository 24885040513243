import get from 'lodash/get';
import React from 'react';
import { RichText } from 'prismic-reactjs';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Button from 'common/src/components/Button';
import Heading from 'common/src/components/Heading';
import Text from 'common/src/components/Text';
import Container from 'common/src/components/UI/Container';
import ContactUsWrapper from './contactUs.style';

const handleHelpScout = () => {
  window.Beacon('open');
};

const Map = () => (
  <iframe
    id="google_map"
    title="Google Map"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.6630883764346!2d145.04258151516228!3d-37.821359779751006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad643d4b3c1dca1%3A0x140ecebab4f5160f!2sUnit%203%2F33%20Malmsbury%20St%2C%20Hawthorn%20VIC%203122!5e0!3m2!1sen!2sau!4v1585892195190!5m2!1sen!2sau"
    width="100%"
    height="100%"
    frameBorder="0"
    allowFullScreen=""
    aria-hidden="false"
    loading="lazy"
  ></iframe>
);

const ContactUs = ({
  row,
  col,
  mapCol,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  featureArea,
  featureTitle,
  featureDescription,
  beaconBtnStyle,
  data,
}) => {
  const contents = get(data, 'body.list.items', []);

  return (
    <ContactUsWrapper id={data.section_key}>
      <Box {...sectionHeader}>
        <Text content={data.heading} {...sectionTitle} />
        <Heading content={data.sub_heading} {...sectionSubTitle} />
      </Box>

      <Box>
        <Container>
          <Box {...row}>
            <Box {...col}>
              {contents.map((content, contentIndex) => (
                <Box key={contentIndex} {...featureArea}>
                  {content.title ? (
                    <Heading content={content.title} {...featureTitle} />
                  ) : null}
                  {(content.description || []).map((description, index) => (
                    <Text
                      key={index}
                      content={RichText.asText([description])}
                      {...featureDescription}
                    />
                  ))}
                </Box>
              ))}
              <Box>
                <Button
                  title={get(data, 'body.button.label', 'Contact HelpDesk')}
                  {...beaconBtnStyle}
                  onClick={handleHelpScout}
                />
              </Box>
            </Box>
            <Box {...mapCol}>
              <Map />
            </Box>
          </Box>
        </Container>
      </Box>
    </ContactUsWrapper>
  );
};

ContactUs.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  data: PropTypes.object,
};

ContactUs.defaultProps = {
  row: {
    marginBottom: '50px',
    flexBox: true,
    flexWrap: 'wrap',
    flexDirection: ['column', 'column', 'row'],
    justifyContent: 'space-evenly',
    border: '1px solid #efefef',
    borderRadius: '3px',
  },
  col: {
    alignSelf: 'flex-start',
    flex: ['1', '1', '2', '1'],
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    padding: ['20px', '20px', '20px', '70px'],
    paddingRight: ['20px', '20px', '20px', '0px'],
    borderRadius: '3px',
  },
  mapCol: {
    flex: ['1', '1', '3', '2'],
    height: ['350px', '350px', 'auto'],
  },
  sectionHeader: {
    marginTop: ['40px', '80px'],
    marginBottom: ['40px', '80px'],
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  sectionTitle: {
    as: 'span',
    marginBottom: '10px',
    marginTop: '0px',
    fontSize: '14px',
    color: '#A73494',
    display: 'block',
    fontWeight: '700',
    textAlign: 'center',
    letterSpacing: '0.15em',
  },
  sectionSubTitle: {
    as: 'h2',
    textAlign: 'center',
    fontSize: ['20px', '24px', '26px', '28px', '30px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    marginBottom: '0px',
    marginTop: '0px',
  },
  featureArea: {
    marginBottom: '1rem',
  },
  featureTitle: {
    as: 'h3',
    fontSize: '20px',
    marginBottom: '8px',
    marginTop: '0px',
    color: 'rgb(15, 33, 55)',
    fontWeight: '400',
    lineHeight: '1.5',
    letterSpacing: '-0.02em',
  },
  featureDescription: {
    marginBottom: '0px',
    marginTop: '0px',
    fontSize: '15px',
    color: 'rgba(52, 61, 72, 0.8)',
    lineHeight: '1.84',
  },
  beaconBtnStyle: {
    minWidth: ['120px', '156px'],
    fontSize: '16px',
    fontWeight: '700',
  },
};

export default ContactUs;
